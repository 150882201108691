import React from "react"
import { Layout as AntLayout, Menu, Avatar, Dropdown } from "antd"
import tw, { css } from "twin.macro"
import { UserOutlined, DownOutlined, LeftOutlined } from "@ant-design/icons"
import { Link } from "gatsby"
import { useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"

import Logo from "@src/assets/logo.svg"
import { Localization } from "@src/localization"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import useCurrentRoute from "@src/hooks/useCurrentRoute"
import { GET_USER, User } from "@src/queries/user"
import Loading from "../loading"
import { logout, getToken } from "@src/utils/auth"

const UserDropdown = () => (
  <Menu>
    <Menu.Item>
      <div
        onClick={() => {
          logout()
          location.href = location.origin + "/logout"
        }}
      >
        Logout
      </div>
    </Menu.Item>
  </Menu>
)

const Header = (): React.ReactElement => {
  const headerContent = React.useContext(Localization).header
  const currentRoute = useCurrentRoute()

  // get user
  const getUserResp = useQuery(GET_USER)

  const menuItems = [
    {
      to: navigationRoutes[PageOptions.PICKTEMPLATE],
      content: headerContent.create,
      toActiveIn: [
        PageOptions.PICKTEMPLATE,
        PageOptions.ADDTEMPLATE,
        PageOptions.COLOR,
        PageOptions.LOGO,
        PageOptions.PHOTO,
        PageOptions.COPY,
        PageOptions.DETAILS,
      ],
    },
    {
      to: navigationRoutes[PageOptions.CAMPAIGNS],
      content: headerContent.campaigns,
      toActiveIn: [PageOptions.CAMPAIGNS],
    },
    {
      to: navigationRoutes[PageOptions.REPORTS],
      content: headerContent.reports,
      toActiveIn: [PageOptions.REPORTS],
    },
    {
      to: navigationRoutes[PageOptions.HELP],
      content: headerContent.help,
      toActiveIn: [PageOptions.HELP],
    },
  ]

  // selected routes
  const selectedKeys: string[] = []
  menuItems.forEach(oneItem => {
    if (currentRoute && oneItem.toActiveIn.includes(currentRoute)) {
      selectedKeys.push(oneItem.to)
    }
  })

  // show loading until user info is available
  if (getUserResp.loading) {
    return <Loading withOverlay />
  }

  const user =
    getUserResp && getUserResp.data && (getUserResp.data.user as User)

  return (
    <AntLayout.Header
      css={[
        tw`text-gray-700 bg-white flex items-center fixed z-50 w-full border-b border-gray-400`,
      ]}
    >
      {/* import fonts */}
      <Helmet>
        <title>Postal</title>
      </Helmet>

      {/* logo */}
      <Link to={user ? "/" : "#"}>
        <Logo tw="w-40" />
      </Link>

      {/* nav links */}
      {user && (
        <>
          <Menu
            selectedKeys={selectedKeys}
            mode="horizontal"
            css={[
              tw`pl-2 lg:pl-8 xl:pl-16`,
              css`
                height: calc(100% - 2px);
              `,
            ]}
          >
            {menuItems.map(menuItem => (
              <Menu.Item
                key={menuItem.to}
                tw="mx-6 p-0"
                style={{ lineHeight: "35px", verticalAlign: "super" }}
              >
                <Link to={menuItem.to}>{menuItem.content}</Link>
              </Menu.Item>
            ))}
          </Menu>

          <div tw="flex flex-grow justify-center">
            <a
              href={`https://www.4patientcare.ws/dashboardv3/web/CombRemPreService.aspx?SessionID=${getToken()}`}
              tw="flex items-center text-sm text-gray-700 leading-4 cursor-pointer"
            >
              <LeftOutlined tw="mr-2" />
              {headerContent.backToDashboard}
            </a>
          </div>

          <div tw="flex items-center cursor-pointer">
            <Avatar icon={<UserOutlined tw="w-full" />} tw="mr-2" />
            <Dropdown
              overlay={UserDropdown}
              trigger={["click"]}
              placement="bottomRight"
            >
              <div tw="flex items-center leading-tight">
                <div tw="mr-2">
                  {user.session.userId}
                  <br />
                  {user.orgName}
                </div>
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </>
      )}
    </AntLayout.Header>
  )
}

export default Header
